import React from 'react';
import './experience.scss';

class Experience extends React.Component {
  render() {
    return (
      <section className="section">
          <h1 className="title is-1 is-flex is-horizontal-center">Previous Experience</h1>
      </section>
    );
  }
}
export default Experience;
